import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  FloatingLabel,
  Dropdown,
  Table,
  Alert,
  Modal,
  FormSelect
} from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

import './Game.sass'
import LoadingHandler from '../../Biometric/StatesComponents/LoadingHandler'
import { GateCount } from '../components/gateCount'
import { utils, read, writeFile } from 'xlsx';

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


function GameTickets() {
  const [terminals, setTerminals] = useState<any>([])
  const [getParcode, setParcode] = useState<any>([])

  const [eventConfig, setEventConfig] = useState<any>()

  const [tickets, setTickets] = useState<any>({
    total: 0,
    sync: 0
  })

  const [downloadFile, setDownloadFile] = useState('')
  
  const [isBepass, setIsBepass] = useState(false)

  const [siemensentranceByGame, setSiemensEntranceByGame] = useState(0)

  const downloadTickets = () => {
    fetch(`${BASE.api_game.base_url}${BASE.api_game.tickets}/${getParcode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api_game.api_key
      },
    })
      .then((resg) => resg.json())
      .then((response) => {
        const worksheet = utils.json_to_sheet(response);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'tickets');
        writeFile(workbook, `tickets-${getParcode}.xlsx`);
        setDownloadFile(response.file)
        // setUserList(response.visitors)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getTickets = (parCode: string) => {

    // setLoader(true)
    fetch(`${BASE.api_game.base_url}${BASE.api_game.ticket_audit}/${parCode}`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((response) => {
        // setLoader(false)

        response.data?.sort((a: any, b: any) => {
          if (a.gateName < b.gateName) {
            return -1;
          }
          if (a.gateName > b.gateName) {
            return 1;
          }
          return 0;
        });
        setTerminals(response.data)
        let totalCount = 0,
          syncCount = 0

        response.data.map((item: any) => {
          if (item.gateTotal > 0) {
            totalCount += Number(item.gateTotal)
            syncCount += Number(item.gateSync)
          }

        })

        setTickets({
          total: totalCount,
          sync: syncCount
        })

      })
      .catch((error) => {
        // setLoader(false)
        console.log(error)
      })


  }


  const [currentEvent, setCurrentEvent] = useState<any>({})

const [show, setShow] = useState(false);

const handleClose = () => {setShow(false); reset()};
const handleShow = () => setShow(true);

const [disableSign, setDisableSign] = useState(false)

const [showConfirm, setShowConfirm] = useState(false);

const handleShowConfirm = () => setShowConfirm(true)
const handleCloseConfirm = () => setShowConfirm(false)

const {
  register,
  handleSubmit,
  setError,
  formState: { errors },
  clearErrors,
  getValues,
  reset
} = useForm()

const [startDate, setStartDate] = useState(new Date())

  const editEvent = (data: any) => {

    fetch(`${BASE.api_game.base_url}${BASE.api_game.active_game}/${currentEvent.id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
          status: 'active',
          home: data.home,
          visitor: data.visitor,
          gameDateTime: startDate,
          gameCode: data.gameCode
        })
    })
        .then((response) => {
          eventConfigData(data)
        })
        .catch((error) => {
            console.log(error)
        })
  }

  
  const modalOpen = () => {

    setTimeout(() => {
      handleShow()
    }, 300);
  }
  


  useEffect(() => {
    getGameList()
    getDefaultEventConfig()

    if(sessionStorage.getItem('role') === 'bedimin'){
      setIsBepass(true)
    }
  
  }, [])


  const getGameList = () => {
    setLoader(true)
    fetch(`${BASE.api_game.base_url}${BASE.api_game.get_games}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      }
    })
      .then((resg) => resg.json())
      .then((response) => {
        // console.log(response)
        

        // setGameList(response)
        // getTickets(response[0].gameCode)
        // setParcode(response[0].gameCode)
        // reset()
        // setCurrentEvent(response[0])
        // setSiemensEntranceByGame(response[0].siemensTotalEntrance)

        const filteredGames = response.filter((game: { home: string }) => game.home.toLowerCase() === 'fluminense');
      
        setGameList(filteredGames);

        if (filteredGames.length > 0) {
          getTickets(filteredGames[0].gameCode)
          setParcode(filteredGames[0].gameCode)
          reset()
          setCurrentEvent(filteredGames[0])
          setSiemensEntranceByGame(filteredGames[0].siemensTotalEntrance)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const [gameList, setGameList] = useState<any[]>([])
  const [loader, setLoader] = useState(false)

  const selectHandler = (parcode: any) => {
    setParcode(parcode)
    getTickets(parcode)
    getEntrancesByGame(parcode)
  }

    function getEntrancesByGame(gameCode: any) {
      console.log('oieeee macarena~')
        for (let i = 0; i < gameList.length; i++) {
            console.log(gameList[i].gameCode ,gameCode)
            if (gameList[i].gameCode === gameCode) {
              reset()
              setCurrentEvent(gameList[i])
                return setSiemensEntranceByGame(gameList[i].siemensTotalEntrance)
            }
        }

        // return null;
    }

    const getDefaultEventConfig = () => {

      fetch(`${BASE.api_game.base_url}${BASE.api_game.get_game_config}/503f00b7-5dbb-457f-af54-b1a37c7d264f`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
      })
      .then((resg) => resg.json())
      .then((response) => {
        setEventConfig(response?.data)
      })
      .catch((error) => {
        console.log('- < ')
          console.log(error)
      })
    }

    const eventConfigData = (data:any) => {

      fetch(`${BASE.api_game.base_url}${BASE.api_game.set_game_config}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            server: data.server,
            apiKey: data.api_key,
            eventCode: data.gameCode,
            companyId: '503f00b7-5dbb-457f-af54-b1a37c7d264f',
            status: 'active',
          })
      })
      .then((resg) => resg.json())
      .then((response) => {
        window.location.reload();
        console.log(response)
      })
      .catch((error) => {
        console.log('- < ')
          console.log(error)
      })
    }



    // Need to dinamyze

    const steps = [
      { name: 'Validando Dispositivos', status: 'idle', timestamp: '--' },
      { name: 'Limpando Dispositivos', status: 'idle', timestamp: '--' },
      { name: 'Carregando Sincronização', status: 'idle', timestamp: '--' },
      { name: 'Iniciando Sincronização', status: 'idle', timestamp: '--' },
      { name: 'Sincronizando', status: 'idle', timestamp: '--' },
    ];
  
    const getBadgeColor = (status:any) => {
      switch (status) {
        case 'done':
          return 'linear-gradient(145deg, #6dd5ed, #2193b0)'; // Blue gradient
        case 'processing':
          return 'linear-gradient(145deg, #f6d365, #fda085)'; // Orange gradient
        case 'idle':
          return 'linear-gradient(145deg, #c3cfe2, #cfd9df)'; // Gray gradient
        default:
          return '#aaa'; // Default color
      }
    };
  
    const getStatusColor = (status:any) => {
      switch (status) {
        case 'offline':
          return '#7e0c32'; // Red gradient
        case 'online':
          return '#0c7e0f'; // Green gradient
        case 'loading':
          return '#fff'; // White gradient
        default:
          return '#ccc'; // Default color
      }
    };
  
    const ipData = [
      { ip: '192.168.7.12', port: 'offline', status: 'offline' },
      { ip: '192.168.7.13', port: 'offline', status: 'offline' },
      { ip: '192.168.7.14', port: 'offline', status: 'offline' },
      { ip: '192.168.7.15', port: 'offline', status: 'offline' },
      { ip: '192.168.7.16', port: 'offline', status: 'offline' },
      { ip: '192.168.7.17', port: 'offline', status: 'offline' },
      { ip: '192.168.7.18', port: 'offline', status: 'offline' },
    ];
  
  return (
    <>
      <div className="game-tickets-header">
        <Row>
          <Col xl={4}>
            <Card.Title as="h5">Selecione um jogo:</Card.Title>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <select
                className="form-control"
                onChange={(event) => { selectHandler(event.target.value) }}
              >
                {gameList.length > 0 &&
                  gameList.map((game) => (
                    <option key={game.gameCode} value={game.gameCode}>
                      {`${game.home} vs ${game.visitor}`}
                    </option>
                  ))
                }
              </select>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Label className="align-center">.</Form.Label>
            <input
              className="btn form-control btn-default"
              type="submit"
              value="Baixar Tickets"
              onClick={downloadTickets}
            />
          </Col>
          {(isBepass && currentEvent.status !== 'active') && (
            <Col md={2}>
            <Form.Label className="align-center">.</Form.Label>
            <input
              className="btn form-control btn-default"
              type="submit"
              value="Iniciar Partida"
              onClick={() => {modalOpen();}}
            />
          </Col>
          )}
        </Row>
      </div>

      <div className="timeline-container">
              <div className="timeline">
                {steps.map((step, index) => (
                  <div key={index} className="timeline-item">
                    <div className="timeline-badge" style={{ background: getBadgeColor(step.status) }}>
                    </div>
                      <span className="timeline-timestamp">{step.timestamp}</span>
                    <div className="timeline-content">
                      <h5>{step.name}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
      <div className="game-tickets-header">
        <span>Ingressos baixados: <Alert variant='default'>{tickets.total}</Alert></span>
        <span>Ingressos sincronizados: <Alert variant={Number(tickets.total) != Number(tickets.sync) ? 'danger' : 'success'}>{tickets.sync}</Alert></span>
        {siemensentranceByGame > 0 && (
          <span>Entradas: <Alert variant={'info'}>{siemensentranceByGame}</Alert></span>
        )}
      </div>

      <Row>
        {terminals &&
          terminals.map((item: any, index: any) => {
            // if (item.gateTotal <= 0) {
            //   return (<></>)
            // }

            return (
              <Col key={index} xl={6}>
                <Card>
                  <Card.Header>
                    <Card.Title as="h5">Portão {item.gateName}</Card.Title>
                    <span className='other-base'><h6 className="gate-name">ticket</h6>{item.gateTotal}</span>
                    <span className={`other-base ${Number(item.gateTotal) != Number(item.gateSync) ? 'danger' : 'default'}`}><h6 className="gate-name">sync</h6>{item.gateSync}</span>
                    <span className='other-base'><h6 className="gate-name">faces</h6>{item.faceTotal || 0}</span>
                  </Card.Header>
                  <Card.Body>
                    <GateCount totalReference={item.faceTotal || 0} devices={item.devices} />
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
      </Row>

      <Modal show={show} onHide={handleClose}>

<Modal.Body>
<Form onSubmit={handleSubmit(editEvent)}>
          

        <Row className="mb-3">
          <Col sm={12} xl={6}>
            <Form.Label>Código MCA:</Form.Label>
            <Form.Control
            placeholder="Código MCA: "
            defaultValue={currentEvent.gameCode}
            {...register('gameCode')}
            />
          </Col>
          <Col sm={12} xl={6}>
            <Form.Label>Data/Hora do jogo</Form.Label>
            <DatePicker
              className="form-control"
              locale="pt-br"
              timeInputLabel='Hora'
              showTimeInput={true}
              dateFormat="dd/MM/yyyy : HH:mm"
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} xl={6}>
            <Form.Label>Time da Casa:</Form.Label>
            <Form.Control
              placeholder="Time da Casa"
              {...register('home')}
              defaultValue={currentEvent.home}
            />
            {errors.email && (
              <span className="label-error">
              </span>
            )}
          </Col>
          <Col sm={12} xl={6}>
            <Form.Label>Time visitante:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Time visitante"
              {...register('visitor')}
              defaultValue={currentEvent.visitor}
            />
          </Col>
        </Row>

        <Row className="mb-3">
        <Col sm={12} xl={6}>
            <Form.Label>Servidor:</Form.Label>
            <Form.Control
            placeholder="Código MCA: "
            defaultValue={eventConfig?.server}
            {...register('server')}
            />
          </Col>
          <Col sm={12} xl={6}>
            <Form.Label>Token:</Form.Label>
            <Form.Control
            placeholder="Código MCA: "
            defaultValue={eventConfig?.apiKey}
            {...register('api_key')}
            />
          </Col>
        </Row>

      <Row>
        <Col sm={12} xl={12}>
          <Form.Group>
            <Button
              disabled={disableSign}
              type="submit"
              className="mb-0 w-100"
              onClick={() => clearErrors()}
            >
              Ativar Partida
            </Button>
          </Form.Group>
        </Col>
      </Row>

  </Form>

</Modal.Body>

  </Modal>
    </>
  )
}

export default GameTickets
